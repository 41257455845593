(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({"/root/lic-frontend-admin/src/js/app.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
var app = angular.module('itz-lic', ['720kb.datepicker', 'ngRoute', 'ngCookies', 'angularMoment']);

app.config(['$routeProvider', '$locationProvider', require('./routes.js')]);

app.run(function(amMoment) {
    amMoment.changeLocale('de');
});

require('./controllers/index');
require('./services/index');
},{"./controllers/index":"/root/lic-frontend-admin/src/js/controllers/index.js","./routes.js":"/root/lic-frontend-admin/src/js/routes.js","./services/index":"/root/lic-frontend-admin/src/js/services/index.js"}],"/root/lic-frontend-admin/src/js/controllers/customerCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
'use strict';

module.exports = function($scope, customerDataService, pageService, $filter) {

    $scope.customers = [];
    $scope.onlyNumbers = /^\d+$/;
    $scope.onlyWorldChars = /\w+/;
    $scope.onlyLetters = /[a-zA-Z]+/;

    function getCustomers() {
        customerDataService.getCustomers(function(value) {
            $scope.customers = value;
        });
    }

    getCustomers();

    $scope.modalCreateCustomer = function() {
        $('#customerModal').modal('toggle');
    };

    $scope.createCustomer = function() {
        var customer = {
            kunden_nr: $scope.customerNo,
            kundenname: $scope.customerName,
            ansprechpartner_kunde: $scope.apKunde || "",
            ansprechpartner_vertrieb: $scope.apVertrieb || "",
            ansprechpartner_technik: $scope.apTechnik || "",
            kunde_passwort: $scope.kunde_passwort,
            admin: 0
        };

        customerDataService.createCustomer(customer);
        $("#customerModal").modal('toggle');
        $("#customerModal").on('hidden.bs.modal', function() {
            $(this).find('form').trigger('reset');
        })
    };

    $scope.refreshCustomer = function() {
        customerDataService.setCustomers();
        customerDataService.getCustomers(function(value) {
            $scope.customers = value;
        });
    };

    $scope.openCustomer = function(customerId) {
        pageService.go('/customer/' + customerId);
    };

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.customers.customer = $filter('orderBy')($scope.customers.customer, $scope.currentOrderField, $scope.orderReversed);
    };
};
},{}],"/root/lic-frontend-admin/src/js/controllers/customerDetailCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 25.04.2016.
 */
'use strict';

module.exports = function($scope, customerDataService, pageService, $routeParams, licenseDataService, productDataService, modulDataService, $filter) {

    $scope.onlyNumbers = /^\d+$/;
    $scope.onlyWorldChars = /\w+/;
    $scope.onlyLetters = /[a-zA-Z]+/;
    $scope.deleted = false;
    $scope.selectedModules = [];

    function openDetail() {
        $scope.editmode = false;
        customerDataService.getCustomer($routeParams.id, function(value) {
            $scope.selectedCustomer = value;
            $scope.selectedCustomer.passwort_alt = $scope.selectedCustomer.passwort;
            if($scope.selectedCustomer != null) {
                licenseDataService.getLicensesByCustomer($scope.selectedCustomer.id, function(lizenz) {
                    $scope.customerId = $scope.selectedCustomer.id;
                    $scope.licenses = lizenz;
                });
            }
        });
        productDataService.getProducts(function(value) {
            $scope.products = value.products;
        });
        customerDataService.getCustomers(function(value) {
            $scope.customers = value.customer;
        });
    }

    openDetail();

    $scope.toggleEditMode = function() {
        $scope.editmode = !$scope.editmode;
    };

    $scope.saveEdit = function() {

        if($scope.selectedCustomer.apKunde == '---') {
            $scope.selectedCustomer.apKunde = '';
        }
        if($scope.selectedCustomer.apVertrieb == '---') {
            $scope.selectedCustomer.apVertrieb = '';
        }
        if($scope.selectedCustomer.apTechnik == '---') {
            $scope.selectedCustomer.apTechnik = '';
        }

        var customerObj = {
            id:                       $scope.selectedCustomer.id,
            kunden_nr:                $scope.selectedCustomer.kundenNr,
            kundenname:               $scope.selectedCustomer.kundenname,
            ansprechpartner_kunde:    $scope.selectedCustomer.apKunde,
            ansprechpartner_vertrieb: $scope.selectedCustomer.apVertrieb,
            ansprechpartner_technik:  $scope.selectedCustomer.apTechnik,
            passwort:                 $scope.selectedCustomer.passwort,
            passwort_alt:             $scope.selectedCustomer.passwort_alt
        };
        customerDataService.updateCustomer(customerObj);
        $scope.toggleEditMode();
    };

    $scope.deleteModal = function() {
        $('#deleteModal').modal('toggle');
    };

    $scope.delete = function() {
        customerDataService.deleteCustomer($scope.selectedCustomer.id);
        $scope.deleted = true;
        $('#deleteModal').modal('hide');
        $('.modal-backdrop').remove();
        pageService.go('/customer');
    };

    $scope.modalCreateLicense = function() {
        $scope.selectedModules = [];
        $('#licenseModal').modal('toggle');
    };

    $scope.toggleSelection = function(id) {
        var index = $scope.selectedModules.indexOf(id);

        if(index > -1) {
            $scope.selectedModules.splice(index, 1);
        } else {
            $scope.selectedModules.push(id);
        }
    };

    $scope.modulStatusChange = function(modul) {
        if(modul.active) {
            modul.count = 1;
        } else {
            modul.count = 0;
        }
    };

    $scope.createLicense = function() {
        var license = {
            cID:           $scope.customerId,
            sID:           $scope.selectedProduct,
            datum_gueltig: $scope.validDate,
            datum_update:  $scope.updateDate
        };

        licenseDataService.createLicense(license, function(value) {
            var licenseID = value;
            if($scope.modules.hasOwnProperty('modules') && Array.isArray($scope.modules.modules)) {
                for(var eintrag in $scope.modules.modules) {
                    if($scope.modules.modules[eintrag].active) {
                        licenseDataService.addLicenseModul(licenseID, $scope.modules.modules[eintrag].id, $scope.modules.modules[eintrag].count);
                    }
                }
            }
        });

        $('#licenseModal').modal('hide');
        $('.modal-backdrop').remove();
    };

    $scope.productUpdated = function(id) {
        $scope.modules = [];
        modulDataService.getModulesByProduct(id, function(value) {
            if(value.hasOwnProperty('modules') && Array.isArray(value.modules)) {
                $scope.modules = value;
                for(var element in $scope.modules.modules) {
                    $scope.modules.modules[element].count = 0;
                    $scope.modules.modules[element].active = 0;
                }
            }
        });
    };

    $scope.openLicense = function(licenseId) {
        pageService.go('/license/' + licenseId);
    };

    $scope.refreshLicense = function() {
        licenseDataService.getLicensesByCustomer($scope.selectedCustomer.id, function(lizenz) {
            $scope.licenses = lizenz;
        });
    };

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.licenses.license = $filter('orderBy')($scope.licenses.license, $scope.currentOrderField, $scope.orderReversed);
    };
};
},{}],"/root/lic-frontend-admin/src/js/controllers/index.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
'use strict';

var app = angular.module('itz-lic');

app.controller('mainCtrl', ['$scope', 'customerDataService', require('./mainCtrl')]);
app.controller('navCtrl', ['pageService', '$rootScope', '$location', require('./navCtrl')]);
app.controller('loginCtrl', ['$scope', '$http', 'authService', 'pageService', require('./loginCtrl')]);

app.controller('customerCtrl', ['$scope', 'customerDataService', 'pageService', '$filter', require('./customerCtrl')]);
app.controller('customerDetailCtrl', ['$scope', 'customerDataService', 'pageService', '$routeParams', 'licenseDataService', 'productDataService', 'modulDataService', '$filter', require('./customerDetailCtrl')]);

app.controller('licenseCtrl', ['$scope', 'licenseDataService', 'pageService', 'productDataService', 'modulDataService', 'customerDataService','$filter', require('./licenseCtrl')]);
app.controller('licenseDetailCtrl', ['$scope', 'licenseDataService', 'pageService', '$routeParams', 'modulDataService', '$route', require('./licenseDetailCtrl')]);

app.controller('productCtrl', ['$scope', 'productDataService', 'pageService', '$filter', require('./productCtrl')]);
app.controller('productDetailCtrl', ['$scope', 'productDataService', 'pageService', '$routeParams', 'modulDataService', '$filter', require('./productDetailCtrl')]);

app.controller('modulCtrl', ['$scope', 'modulDataService', 'pageService', 'productDataService', '$filter', require('./modulCtrl')]);
app.controller('modulDetailCtrl', ['$scope', 'modulDataService', 'pageService', '$routeParams', 'productDataService', require('./modulDetailCtrl')]);

app.controller('statisticCtrl', ['$scope', 'pageService', 'statisticDataService', '$filter', require('./statisticCtrl')]);
app.controller('statisticDetailCtrl', ['$scope', 'pageService', 'statisticDataService', '$filter', '$routeParams', require('./statisticDetailCtrl')]);
},{"./customerCtrl":"/root/lic-frontend-admin/src/js/controllers/customerCtrl.js","./customerDetailCtrl":"/root/lic-frontend-admin/src/js/controllers/customerDetailCtrl.js","./licenseCtrl":"/root/lic-frontend-admin/src/js/controllers/licenseCtrl.js","./licenseDetailCtrl":"/root/lic-frontend-admin/src/js/controllers/licenseDetailCtrl.js","./loginCtrl":"/root/lic-frontend-admin/src/js/controllers/loginCtrl.js","./mainCtrl":"/root/lic-frontend-admin/src/js/controllers/mainCtrl.js","./modulCtrl":"/root/lic-frontend-admin/src/js/controllers/modulCtrl.js","./modulDetailCtrl":"/root/lic-frontend-admin/src/js/controllers/modulDetailCtrl.js","./navCtrl":"/root/lic-frontend-admin/src/js/controllers/navCtrl.js","./productCtrl":"/root/lic-frontend-admin/src/js/controllers/productCtrl.js","./productDetailCtrl":"/root/lic-frontend-admin/src/js/controllers/productDetailCtrl.js","./statisticCtrl":"/root/lic-frontend-admin/src/js/controllers/statisticCtrl.js","./statisticDetailCtrl":"/root/lic-frontend-admin/src/js/controllers/statisticDetailCtrl.js"}],"/root/lic-frontend-admin/src/js/controllers/licenseCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 26.04.2016.
 */
'use strict';

module.exports = function($scope, licenseDataService, pageService, productDataService, modulDataService, customerDataService, $filter) {

    $scope.selectedModules = [];
    $scope.modules = null;

    function getLicenses() {
        licenseDataService.getLicenses(function(value) {
            $scope.licenses = value;
        });
        productDataService.getProducts(function(value) {
            $scope.products = value.products;
        });
        customerDataService.getCustomers(function(value) {
            $scope.customers = value.customer;
        });
    }

    getLicenses();

    $scope.modalCreateLicense = function() {
        $scope.selectedModules = [];
        $scope.selectedProduct = null;

        $('#licenseModal').modal('toggle');
    };

    $scope.productUpdated = function(id) {
        $scope.modules = [];
        modulDataService.getModulesByProduct(id, function(value) {
            if(value.hasOwnProperty('modules') && Array.isArray(value.modules)) {
                $scope.modules = value;
                for(var element in $scope.modules.modules) {
                    $scope.modules.modules[element].count = 0;
                    $scope.modules.modules[element].active = 0;
                }
            }
        });
    };

    $scope.toggleSelection = function(id) {
        var index = $scope.selectedModules.indexOf(id);

        if(index > -1) {
            $scope.selectedModules.splice(index, 1);
        } else {
            $scope.selectedModules.push(id);
        }
    };

    $scope.modulStatusChange = function(modul) {
        if(modul.active) {
            modul.count = 1;
        } else {
            modul.count = 0;
        }
    };

    $scope.createLicense = function() {
        var license = {
            cID:           $scope.customerId,
            sID:           $scope.selectedProduct,
            datum_gueltig: $scope.validDate,
            datum_update:  $scope.updateDate
        };

        licenseDataService.createLicense(license, function(value) {
            var licenseID = value;
            if($scope.modules.hasOwnProperty('modules') && Array.isArray($scope.modules.modules)) {
                for(var eintrag in $scope.modules.modules) {
                    if($scope.modules.modules[eintrag].active) {
                        licenseDataService.addLicenseModul(licenseID, $scope.modules.modules[eintrag].id, $scope.modules.modules[eintrag].count);
                    }
                }
            }
        });

        $('#licenseModal').modal('toggle');
        $('#licenseModal').on('hidden.bs.modal', function() {
            $(this).find('form').trigger('reset');
        });
    };

    $scope.openLicense = function(licenseId) {
        pageService.go('/license/' + licenseId);
    };

    $scope.refreshLicense = function() {
        licenseDataService.setLicenses();
        licenseDataService.getLicenses(function(value) {
            $scope.licenses = value;
        });
    };

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.licenses.license = $filter('orderBy')($scope.licenses.license, $scope.currentOrderField, $scope.orderReversed);
    };
};
},{}],"/root/lic-frontend-admin/src/js/controllers/licenseDetailCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 27.04.2016.
 */

'use strict';

module.exports = function($scope, licenseDataService, pageService, $routeParams, modulDataService, $route) {

    //Methoden stehen oben, da sie vor dem Aufruf definiert sein müssen.
    //Prüft ob das Modul zur Zeit lizensiert wird
    $scope.checkModuleLicensed = function(modul) {
        var index = $scope.modules.modules.indexOf(modul);
        if(index === -1) {
            return false;
        }
        return $scope.modules.modules[$scope.modules.modules.indexOf(modul)].active;
    };

    function openDetail() {
        $scope.editmode = false;
        licenseDataService.getLicense($routeParams.id, function(value) {
            $scope.selectedLicense = value;
            $scope.selectedLicense.datumGueltig = moment($scope.selectedLicense.datumGueltig).format('DD.MM.YYYY');
            $scope.selectedLicense.datumUpdate = moment($scope.selectedLicense.datumUpdate).format('DD.MM.YYYY');
            modulDataService.getModulesByProduct($scope.selectedLicense.produkt, function(value) {
                if($scope.selectedLicense.module != null) {
                    var moduleArray = $scope.selectedLicense.module.split(',').map(Number).filter(Boolean);
                    var moduleCount = $scope.selectedLicense.module_anzahl.split(',').map(Number).filter(Boolean);
                    if(value.hasOwnProperty('modules') && Array.isArray(value.modules)) {
                        $scope.modules = value;
                        for(var element in $scope.modules.modules) {
                            for(var entry in moduleArray) {
                                if($scope.modules.modules[element].id == moduleArray[entry]) {
                                    $scope.modules.modules[element].count = moduleCount[entry];
                                    $scope.modules.modules[element].active = 1;
                                    break;
                                }
                            }
                            if(!$scope.modules.modules[element].hasOwnProperty('active')) {
                                $scope.modules.modules[element].count = 0;
                                $scope.modules.modules[element].active = 0;
                            }
                        }
                    }
                }
                else {
                    if(value.hasOwnProperty('modules') && Array.isArray(value.modules)) {
                        $scope.modules = value;
                        for(var element in $scope.modules.modules) {
                            $scope.modules.modules[element].count = 0;
                            $scope.modules.modules[element].active = 0;
                        }
                    }
                }
            });
            licenseDataService.getProtokoll($routeParams.id, function(value) {
                $scope.protokoll = value;
            });
        });

    }

    openDetail();

    $scope.toggleEditMode = function() {
        if(!$scope.editmode) {
            $scope.module_backup = jQuery.extend(true, {}, $scope.modules);
        } else {
            $scope.modules = jQuery.extend(true, {}, $scope.module_backup);
        }
        $scope.editmode = !$scope.editmode;
    };

    $scope.modulStatusChange = function(modul) {
        if(modul.active) {
            modul.count = 1;
        } else {
            modul.count = 0;
        }
    };

    $scope.saveEdit = function() {
        var licenseObj = {
            id:            $routeParams.id,
            datum_update:  $scope.selectedLicense.datumUpdate,
            datum_gueltig: $scope.selectedLicense.datumGueltig,
            hardwareID:    $scope.selectedLicense.hardwareID
        };
        licenseDataService.updateLicense(licenseObj);

        if($scope.modules.hasOwnProperty('modules') && Array.isArray($scope.modules.modules)) {
            for(var eintrag in $scope.modules.modules) {
                if($scope.modules.modules[eintrag].active) {
                    licenseDataService.addLicenseModul($routeParams.id, $scope.modules.modules[eintrag].id, $scope.modules.modules[eintrag].count);
                } else {
                    licenseDataService.removeLicenseModul($routeParams.id, $scope.modules.modules[eintrag].id);
                }
            }
        }
        pageService.go(/customer/ + $scope.selectedLicense.kunde);
    };

    $scope.openDeleteModal = function() {
        $('#deleteModal').modal('toggle');
    };

    $scope.delete = function() {
        licenseDataService.deleteLicense($routeParams.id);
        $('#deleteModal').modal('hide');
        $('.modal-backdrop').remove();
        pageService.go('/license');
    };

    $scope.activateLicense = function() {
        licenseDataService.activateLicense($routeParams.id, function(value) {
            $route.reload();
        });
    };

    $scope.deactivateLicense = function() {
        licenseDataService.deactivateLicense($routeParams.id, function(value) {
            $route.reload();
        });
    };
};
},{}],"/root/lic-frontend-admin/src/js/controllers/loginCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
'use strict';

module.exports = function($scope, $http, authService, pageService) {

    $scope.submit = function() {
        var postBody = {benutzername: $scope.username, passwort: $scope.password};

        $http.post('/api/v2/login', postBody)
            .then(function(response) {
                var token = response.data.token;

                authService.setToken(token);
                $('#loginModal').modal('hide');
                $('.modal-backdrop').remove();
                pageService.go('/customer');


            }, function(err) {
                alert('Error: ' + err.data +  ' ... ' + err.status);
            });
    };

    function init() {
        $http.get('/api/v2/customer?token=' + authService.getToken())
            .then(function(response) {
                $('#loginModal').modal('hide');
                $('.modal-backdrop').remove();
                pageService.go('/customer');
            }, function(err) {
                if(err.status == 403) {
                    $('#loginModal').modal('show');
                } else {
                    console.log(err);
                }
            });
    }

    document.getElementById('loginModal').onkeyup = function(e) {
        var key = e.keyCode ? e.keyCode : e.which;

        if(key == 13) {
            $scope.submit();
        }
    };

    init();
};
},{}],"/root/lic-frontend-admin/src/js/controllers/mainCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
'use strict';

module.exports = function($scope, customerDataService) {

    //$scope.errorHandling = customerDataService.errorObj;
};
},{}],"/root/lic-frontend-admin/src/js/controllers/modulCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 27.04.2016.
 */
'use strict';

module.exports = function($scope, modulDataService, pageService, productDataService, $filter) {

    $scope.modules = [];


    function getModules() {
        modulDataService.getModules(function(value) {
            $scope.modules = value;
        });
        productDataService.getProducts(function(value) {
            $scope.products = value.products;
        });
    }

    getModules();

    $scope.modalCreateModul = function() {
        $("#moduleModal").modal('toggle');
    };

    $scope.createModul = function() {
        var modul = {
            bezeichnung: $scope.modulname,
            produkt: $scope.selectedProduct,
            ident: $scope.ident,
            countable: $scope.countable
        };
        modulDataService.createModul(modul);
        $scope.countable = 0;
        $('#moduleModal').modal('toggle');
        $('#moduleModal').on('hidden.bs.modal', function() {
            $(this).find('form').trigger('reset');
        })
    };

    $scope.refreshModul = function() {
        modulDataService.setModules();
        modulDataService.getModules(function(value) {
            $scope.modules = value;
        });
    };

    $scope.openModul = function(modulID) {
        pageService.go('/modules/' + modulID);
    };

    var currentOrderField = 'id';
    var orderReversed = true;

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.modules.modules = $filter('orderBy')($scope.modules.modules, $scope.currentOrderField, $scope.orderReversed);
    };
};
},{}],"/root/lic-frontend-admin/src/js/controllers/modulDetailCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 27.04.2016.
 */
'use strict';

module.exports = function($scope, modulDataService, pageService, $routeParams, productDataService) {
    $scope.deleted = false;

    function openDetail() {
        $scope.editmode = false;
        modulDataService.getModul($routeParams.id, function(value) {
            $scope.selectedModul = value;
        });
        productDataService.getProducts(function(value) {
            $scope.products = value.products;
        });
    }

    openDetail();

    $scope.toggleEditMode = function() {
        $scope.editmode = !$scope.editmode;
    };

    $scope.saveEdit = function() {
        var modulObj = {
            id: $scope.selectedModul.id,
            bezeichnung: $scope.selectedModul.bezeichnung,
            produkt: $scope.selectedModul.produkt,
            ident: $scope.selectedModul.ident,
            countable: $scope.selectedModul.countable
        };
        console.log(modulObj);
        modulDataService.updateModul(modulObj);
        $scope.toggleEditMode();
    };

    $scope.deleteModal = function() {
        $('#deleteModal').modal('toggle');
    };

    $scope.delete = function() {
        modulDataService.deleteModul($scope.selectedModul.id);
        $scope.deleted = true;
        $('#deleteModal').modal('hide');
        $('.modal-backdrop').remove();
        pageService.go('/modules');
    };
};
},{}],"/root/lic-frontend-admin/src/js/controllers/navCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
'use strict';

module.exports = function(pageService, $rootScope, $location) {
    this.go = function (path) {
        pageService.go(path);
    };

    $rootScope.$on('$locationChangeStart', function(event) {
        // console.log('Pfad: ' + $location.path());
        // if ($scope.form.$invalid) {
        //     event.preventDefault();
        // }
    });
};
},{}],"/root/lic-frontend-admin/src/js/controllers/productCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 27.04.2016.
 */
'use strict';

module.exports = function($scope, productDataService, pageService, $filter) {

    $scope.versionPattern = /^\d+.\d+.\d+.\d+$/;
    $scope.products = [];

    function getProducts() {
        productDataService.getProducts(function(value) {
            $scope.products = value;
        });
    }

    getProducts();

    $scope.modalCreateProduct = function() {
        $('#productModal').modal('toggle');
    };

    $scope.createProduct = function() {
        var product = {
            produktname:     $scope.produktName,
            aktuelleVersion: $scope.productVersion,
            ident:           $scope.ident
        };

        productDataService.createProduct(product);
        $('#productModal').modal('toggle');
        $('#productModal').on('hidden.bs.modal', function() {
            $(this).find('form').trigger('reset');
        });
    };

    $scope.refreshProduct = function() {
        productDataService.setProducts();
        productDataService.getProducts(function(value) {
            $scope.products = value;
        });
    };

    $scope.openProduct = function(productID) {
        pageService.go('/product/' + productID);
    };

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.products.products = $filter('orderBy')($scope.products.products, $scope.currentOrderField, $scope.orderReversed);
    };
};
},{}],"/root/lic-frontend-admin/src/js/controllers/productDetailCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 27.04.2016.
 */
'use strict';
module.exports = function($scope, productDataService, pageService, $routeParams, modulDataService, $filter) {
    
    $scope.versionPattern = /^\d+.\d+.\d+.\d+$/;
    $scope.modules = [];

    function openDetail() {
        $scope.editmode = false;
        productDataService.getProduct($routeParams.id, function(value) {
            $scope.selectedProduct = value;
            $scope.productId = value.id;
            modulDataService.getModulesByProduct($routeParams.id, function (value) {
                $scope.modules = value;
            })
        });
        productDataService.getProducts(function(value) {
            $scope.products = value.products;
        });
    }

    openDetail();

    $scope.toggleEditMode = function() {
        $scope.editmode = !$scope.editmode;
    };

    $scope.saveEdit = function() {
        var productObj = {
            id: $scope.selectedProduct.id,
            produktname: $scope.selectedProduct.produktname,
            aktuelleVersion: $scope.selectedProduct.aktuelleVersion,
            ident: $scope.selectedProduct.ident
        };
        productDataService.updateProduct(productObj);
        $scope.toggleEditMode();
    };

    $scope.deleteModal = function() {
        $('#deleteModal').modal('toggle');
    };

    $scope.delete = function() {
        productDataService.deleteProduct($scope.selectedProduct.id);
        $scope.deleted = true;
        $('#deleteModal').modal('hide');
        $('.modal-backdrop').remove();
        pageService.go('/product')
    };


    $scope.modalCreateModul = function() {
        $("#moduleModal").modal('toggle');
    };

    $scope.createModul = function() {
        var modul = {
            bezeichnung: $scope.modulname,
            produkt: $scope.selectedProduct.id,
            ident: $scope.ident
        };
        modulDataService.createModul(modul);
        $('#moduleModal').modal('toggle');
        $('#moduleModal').on('hidden.bs.modal', function() {
            $(this).find('form').trigger('reset');
        })
    };

    $scope.refreshModul = function() {
        modulDataService.setModules();
        modulDataService.getModulesByProduct($routeParams.id, function (value) {
            $scope.modules = value;
        });
    };

    $scope.openModul = function(modulID) {
        pageService.go('/modules/' + modulID);
    };

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.modules.modules = $filter('orderBy')($scope.modules.modules, $scope.currentOrderField, $scope.orderReversed);
    };
};


},{}],"/root/lic-frontend-admin/src/js/controllers/statisticCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
module.exports = function($scope, pageService, statisticDataService, $filter) {

    $scope.statistics = [];

    $scope.refreshStatistic = function() {
        statisticDataService.getStatistic(function(value) {
            $scope.statistics = value;
        })
    };

    function init() {
        statisticDataService.getStatistic(function(value) {
            $scope.statistics = value;
        })
    }

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.statistics = $filter('orderBy')($scope.statistics, $scope.currentOrderField, $scope.orderReversed);
    };

    $scope.openStatistic = function(id) {
        pageService.go('/statistic/' + id);
    };

    init();
};
},{}],"/root/lic-frontend-admin/src/js/controllers/statisticDetailCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
module.exports = function($scope, pageService, statisticDataService, $filter, $routeParams) {

    function init() {
        statisticDataService.getStatisticByCustomer($routeParams.id, function(value) {
            $scope.statistic = value;
            if($scope.statistic.length > 0) {
                $scope.customername = $scope.statistic[0].kundenname;
            }
        });
    }

    $scope.getOrderIconClass = function() {
        return $scope.orderReversed ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up';
    };

    // Prüft ob nach dem angegebenen Feld sortiert wurde
    $scope.showOrderIcon = function(field) {
        return field === $scope.currentOrderField;
    };

    $scope.orderBy = function(field) {
        if($scope.currentOrderField === field) {
            $scope.orderReversed = !$scope.orderReversed;
        } else {
            $scope.currentOrderField = field;
            $scope.orderReversed = false;
        }
        $scope.statistic = $filter('orderBy')($scope.statistic, $scope.currentOrderField, $scope.orderReversed);
    };

    init();

};
},{}],"/root/lic-frontend-admin/src/js/routes.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 18.05.2016.
 */
'use strict';

module.exports = function ($routeProvider, $locationProvider) {
    // route definition
    $routeProvider
        .when('/', {
            controller: 'loginCtrl',
            templateUrl: '../templates/partials/login.html'
        })
        .when('/customer', {
            controller: 'customerCtrl',
            templateUrl: '../templates/partials/customer/listView.html'
        })
        .when('/customer/:id',{
            controller: 'customerDetailCtrl',
            templateUrl: '../templates/partials/customer/detailView.html'
        })
        .when('/license',{
            controller: 'licenseCtrl',
            templateUrl: '../templates/partials/license/listView.html'
        })
        .when('/license/:id',{
            controller: 'licenseDetailCtrl',
            templateUrl: '../templates/partials/license/detailView.html'
        })
        .when('/product',{
            controller: 'productCtrl',
            templateUrl: '../templates/partials/product/listView.html'
        })
        .when('/product/:id',{
            controller: 'productDetailCtrl',
            templateUrl: '../templates/partials/product/detailView.html'
        })
        .when('/modules',{
            controller: 'modulCtrl',
            templateUrl: '../templates/partials/modul/listView.html'
        })
        .when('/modules/:id',{
            controller: 'modulDetailCtrl',
            templateUrl: '../templates/partials/modul/detailView.html'
        })
        .when('/statistic', {
            controller: 'statisticCtrl',
            templateUrl: '../templates/partials/statistic/listView.html'
        })
        .when('/statistic/:id', {
            controller: 'statisticDetailCtrl',
            templateUrl: '../templates/partials/statistic/detailView.html'
        })
        .otherwise({
            redirectTo: '/'
        });
    $locationProvider.html5Mode(true);
};
},{}],"/root/lic-frontend-admin/src/js/services/authService.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
'use strict';

module.exports = function($cookies, $window) {
    var token = undefined;

    this.setToken = function(t) {
        var now = new $window.Date(),
            exp = new $window.Date(now.getFullYear(), now.getMonth(), now.getDate() + 7, now.getHours(), now.getMinutes());

        $cookies.put('lictoken', JSON.stringify(t), {
            expires: exp
        });
        token = t;
    };

    this.getToken = function() {
        if(!token){
            var cookie = $cookies.get('lictoken');

            if(cookie == undefined) {
                $('#loginModal').modal('show');
                return undefined;
            }
            this.setToken(JSON.parse(cookie));
        }
        return token;
    };
};
},{}],"/root/lic-frontend-admin/src/js/services/customerDataService.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 20.04.2016.
 */
'use strict';

module.exports = function($http, authService, pageService) {

    var errorObj = {
        error: null
    };

    var customerList = {customer: null};

    this.setCustomers = function(cb) {
        $http.get('/api/v2/customer?token=' + authService.getToken())
            .then(function(res) {
                customerList.customer = res.data;
                if(cb)
                    cb(customerList);
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.getCustomers = function(cb) {
        var that = this;
        if(customerList.customer != null) {
            cb(customerList);
        } else {
            that.setCustomers(function() {
                cb(customerList);
            })
        }
    };

    this.getCustomer = function(id, cb) {
        this.getCustomers(function() {
            for(var kunde in customerList.customer) {
                if(customerList.customer[kunde].id == id) {
                    cb(customerList.customer[kunde]);
                }
            }
        })
    };

    this.createCustomer = function(customer) {
        var that = this;
        $http.post('/api/v2/customer?token=' + authService.getToken(), customer)
            .then(function(res) {
                that.setCustomers();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.updateCustomer = function(customer) {
        var that = this;
        $http.put('/api/v2/customer/'+customer.id+'?token=' + authService.getToken(), customer)
            .then(function(res) {
                that.setCustomers();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.deleteCustomer = function(id) {
        var that = this;
        $http.delete('/api/v2/customer/'+id+'?token=' + authService.getToken())
            .then(function(res) {
                that.setCustomers();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };
};
},{}],"/root/lic-frontend-admin/src/js/services/index.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 13.04.2016.
 */
'use strict';

var app = angular.module('itz-lic');

app.service('authService', ['$cookies', '$window', require('./authService')]);
app.service('pageService', require('./pageChangeService'));


app.service('customerDataService', ['$http', 'authService', 'pageService', require('./customerDataService')]);
app.service('licenseDataService', ['$http', 'authService', 'pageService', require('./licenseDataService')]);
app.service('productDataService', ['$http', 'authService', 'pageService', require('./productDataService')]);
app.service('modulDataService', ['$http', 'authService', 'pageService', require('./modulDataService')]);
app.service('statisticDataService', ['$http', 'authService', 'pageService', require('./statisticDataService')]);
},{"./authService":"/root/lic-frontend-admin/src/js/services/authService.js","./customerDataService":"/root/lic-frontend-admin/src/js/services/customerDataService.js","./licenseDataService":"/root/lic-frontend-admin/src/js/services/licenseDataService.js","./modulDataService":"/root/lic-frontend-admin/src/js/services/modulDataService.js","./pageChangeService":"/root/lic-frontend-admin/src/js/services/pageChangeService.js","./productDataService":"/root/lic-frontend-admin/src/js/services/productDataService.js","./statisticDataService":"/root/lic-frontend-admin/src/js/services/statisticDataService.js"}],"/root/lic-frontend-admin/src/js/services/licenseDataService.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 26.04.2016.
 */
'use strict';

module.exports = function($http, authService, pageService) {

    var errorObj = {
        error: null
    };
    var license = {license: null};
    var licenseCustomer = {license: null};
    var protokoll = [];

    this.setLicenses = function(cb) {
        $http.get('/api/v2/license?token=' + authService.getToken())
            .then(function(res) {
                license.license = res.data;
                if(cb) {
                    cb(license);
                }
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.getLicenses = function(cb) {
        var that = this;
        if(license.license != null) {
            cb(license);
        } else {
            that.setLicenses(function() {
                cb(license);
            });
        }
    };

    this.getLicense = function(id, cb) {
        this.getLicenses(function() {
            for(var lic in license.license) {
                if(license.license[lic].id == id) {
                    //Übergebe eine Kopie der Lizenz (Call-By-Value)
                    //Referenz würden die Tabellendatensätze zerstören
                    cb(angular.copy(license.license[lic]));
                }
            }
        });
    };

    this.createLicense = function(license, cb) {
        var that = this;
        $http.post('/api/v2/license/' + license.sID + '/' + license.cID + '?token=' + authService.getToken(), license)
            .then(function(res) {
                that.setLicenses();
                that.getLicensesByCustomer(license.cID);
                cb(res.data[0].id);
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.addLicenseModul = function(lId, mId, anzahl) {
        var that = this;
        $http.post('/api/v2/license/' + lId + '/modul/' + mId + '?token=' + authService.getToken(), {anzahl: anzahl})
            .then(function(res) {
                that.setLicenses();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.updateLicense = function(license) {
        var that = this;
        $http.put('/api/v2/license/' + license.id + '?token=' + authService.getToken(), license)
            .then(function(res) {
                that.setLicenses();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.removeLicenseModul = function(lId, mId) {
        var that = this;
        $http.delete('/api/v2/license/' + lId + '/modul/' + mId + '?token=' + authService.getToken())
            .then(function(res) {
                that.setLicenses();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.getLicensesByCustomer = function(customer, cb) {
        $http.get('/api/v2/license/customer/' + customer + '?token=' + authService.getToken())
            .then(function(res) {
                licenseCustomer.license = res.data;
                if(cb) {
                    cb(licenseCustomer);
                }
            }, function(err) {
                license.license = null;
                if(err.status = '404') {
                    if(cb) {
                        cb(undefined);
                    }
                } else if(err.status == 403) {
                    pageService.go('/');
                }
                errorObj.error = err;
            });
    };

    this.deleteLicense = function(id) {
        var that = this;
        $http.delete('/api/v2/license/remove/' + id + '?token=' + authService.getToken())
            .then(function(res) {
                that.setLicenses();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.activateLicense = function(id, cb) {
        var that = this;
        $http.put('/api/v2/license/activate/' + id + '?token=' + authService.getToken())
            .then(function(res) {
                that.setLicenses(function() {
                    cb();
                });
                cb(1);
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.deactivateLicense = function(id, cb) {
        var that = this;
        $http.put('/api/v2/license/deactivate/' + id + '?token=' + authService.getToken())
            .then(function(res) {
                that.setLicenses(function() {
                    cb();
                });
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.getProtokoll = function(id, cb) {
        $http.get('/api/v2/license/protocol/' + id + '?token=' + authService.getToken())
            .then(function(res) {
                protokoll = res.data;
                if(cb) {
                    cb(protokoll);
                }
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

};
},{}],"/root/lic-frontend-admin/src/js/services/modulDataService.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 27.04.2016.
 */
'use strict';

module.exports = function($http, authService, pageService) {

    var errorObj = {
        error: null
    };

    var modulList = {modules: null};
    var modulesByProduct = {modules: null};


    this.setModules = function(cb) {
        $http.get('/api/v2/modul?token=' + authService.getToken())
            .then(function(res) {
                modulList.modules = res.data;
                if(cb)
                    cb(modulList)
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.getModules = function(cb) {
        var that = this;
        if(modulList.modules != null)
            cb(modulList);
        else {
            that.setModules(function() {
                cb(modulList);
            })
        }
    };

    this.getModulesByProduct = function(id, cb) {
        $http.get('/api/v2/modul/software/'+id+'?token='+authService.getToken())
            .then(function(res) {
                modulesByProduct.modules = res.data;
                if(cb)
                    cb(modulesByProduct);
            }, function(err) {
                errorObj.error = err;
                cb(null);
            })
    };
    
    this.getModul = function(id, cb) {
        this.getModules(function() {
            for(var modul in modulList.modules){
                if(modulList.modules[modul].id == id){
                    cb(modulList.modules[modul]);
                }
            }
        })
    };

    this.createModul = function(modul) {
        var that = this;
        $http.post('/api/v2/modul/'+modul.produkt + '?token=' + authService.getToken(), modul)
            .then(function(res) {
                that.setModules();
                that.getModulesByProduct(modul.produkt);
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.updateModul = function(modul) {
        var that = this;
        $http.put('/api/v2/modul/'+modul.id+'?token='+authService.getToken(), modul)
            .then(function(res) {
                that.setModules();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.deleteModul = function(id) {
        var that = this;
        $http.delete('/api/v2/modul/'+id+'?token='+authService.getToken())
            .then(function(res) {
                that.setModules();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    }
};
},{}],"/root/lic-frontend-admin/src/js/services/pageChangeService.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 18.04.2016.
 */

'use strict';

module.exports = function($location) {
    this.go = function(path) {
        $location.path(path);
    };
};
},{}],"/root/lic-frontend-admin/src/js/services/productDataService.js":[function(require,module,exports){
/**
 * Created by jroerthmans on 27.04.2016.
 */
'use strict';

module.exports = function($http, authService, pageService) {

    var errorObj = {
        error: null
    };

    var productList = {products: null};

    this.setProducts = function(cb) {
        $http.get('/api/v2/product?token=' + authService.getToken())
            .then(function(res) {
                productList.products = res.data;
                if(cb)
                    cb(productList);
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.getProducts = function(cb) {
        var that = this;
        if(productList.products != null) {
            cb(productList);
        } else {
            that.setProducts(function() {
                cb(productList);
            })
        }
    };

    this.getProduct = function(id, cb) {
        this.getProducts(function() {
            for(var product in productList.products) {
                if(productList.products[product].id == id) {
                    cb(productList.products[product]);
                }
            }
        })
    };

    this.createProduct = function(product) {
        var that = this;
        $http.post('/api/v2/product?token=' + authService.getToken(), product)
            .then(function(res) {
                that.setProducts();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.updateProduct = function(product) {
        var that = this;
        $http.put('/api/v2/product/'+product.id+'?token='+authService.getToken(), product)
            .then(function(res) {
                that.setProducts();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            })
    };

    this.deleteProduct = function(id) {
        var that = this;
        $http.delete('/api/v2/product/'+id+'?token='+authService.getToken())
            .then(function(res) {
                that.setProducts();
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            })
    }
};
},{}],"/root/lic-frontend-admin/src/js/services/statisticDataService.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */

module.exports = function($http, authService, pageService) {

    var errorObj = {
        error: null
    };

    var statisticList = [];
    var statisticDetail = [];

    this.getStatistic = function(cb) {
        $http.get('/api/v2/usage/fibusync?token=' + authService.getToken())
            .then(function(res) {
                statisticList = res.data;
                if(cb) {
                    cb(statisticList);
                }
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };

    this.getStatisticByCustomer = function(id, cb) {
        $http.get('/api/v2/usage/fibusync/customer/' + id + '?token=' + authService.getToken())
            .then(function(res) {
                statisticDetail = res.data;
                if(cb) {
                    cb(statisticDetail);
                }
            }, function(err) {
                errorObj.error = err;
                if(err.status == 403) {
                    pageService.go('/');
                }
            });
    };
};



},{}]},{},["/root/lic-frontend-admin/src/js/app.js"]);
